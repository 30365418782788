import * as http from '@/utils/api'
import {
  CLEAR_CHAT_SEARCH_WORD,
  DELETE_CHAT,
  FETCH_CHATS,
  INPUT_CHAT_SEARCH_WORD,
  PATCH_CHAT,
  PATCH_CHAT_MEMBER,
  POST_CHAT,
  PUT_CHAT,
  PUT_CHAT_MEMBERS,
  PUT_CHAT_OWNER,
  SELECT_CHAT_TYPE,
  SET_SELECTED_CHAT
} from './types'
import {
  ENDPOINT_CHAT,
  ENDPOINT_CHAT_MEMBER,
  ENDPOINT_CHAT_MEMBERS,
  ENDPOINT_CHAT_OWNER,
  ENDPOINT_CHATS
} from '@/constants/endpoint'
import {
  CHAT_TYPE,
  CHAT_TYPE_NAME,
  LOADING_TYPE_CHAT,
  LOADING_TYPE_DELETE_CHAT,
  LOADING_TYPE_PATCH_CHAT,
  LOADING_TYPE_PUT_CHAT_OWNER,
  LOADING_TYPE_REGISTER_CHAT,
  LOADING_TYPE_STAFF_CHATS
} from '@/constants/constant'
import { replace_all } from '@/utils/common'

export default {
  state: {
    chats: [],
    chatSearchWord: '',
    selectedChatType: CHAT_TYPE.GROUP,
    newChatId: 0,
    selectedChat: null
  },
  mutations: {
    [FETCH_CHATS](state, responseData) {
      state.chats = responseData
    },
    [POST_CHAT](state, responseData) {
      state.newChatId = responseData.chat_id
    },
    [INPUT_CHAT_SEARCH_WORD](state, payload) {
      state.chatSearchWord = payload
    },
    [CLEAR_CHAT_SEARCH_WORD](state) {
      state.chatSearchWord = ''
    },
    [SELECT_CHAT_TYPE](state, payload) {
      state.selectedChatType = payload
    },
    [SET_SELECTED_CHAT](state, payload) {
      state.selectedChat = payload
    }
  },
  getters: {
    chats: (state, getters) => {
      let chats = state.chats.map(c => {
        let direct_member = null
        let chat_name = c.chat_name
        let chat_color = c.chat_color
        let chat_img_url = ''
        if (c.chat_type === CHAT_TYPE.INDIVIDUAL) {
          const member = c.members.find(m => m.user_id !== getters.me.user_id)
          if (member) {
            direct_member = {
              ...member
            }
            chat_name = member.last_name + ' ' + member.first_name
            chat_color = member.profile_color
            chat_img_url = member.profile_img_url
          }
        }

        return {
          ...c,
          last_message: c.last_message
            ? replace_all(c.last_message, '\n', '')
                .replace(/\[引用 (.*)](.*)\[\/引用]/, '')
                .replace(/\[返信 (.*)](.*)\[\/返信]/, '')
            : '',
          chat_name,
          chat_color,
          chat_img_url,
          direct_member,
          is_group: c.chat_type === CHAT_TYPE.GROUP
        }
      })

      if (state.selectedChatType === CHAT_TYPE.GROUP) {
        chats = chats.filter(c => c.chat_type === CHAT_TYPE.GROUP)
      } else if (state.selectedChatType === CHAT_TYPE.INDIVIDUAL) {
        chats = chats.filter(c => c.chat_type === CHAT_TYPE.INDIVIDUAL)
      }

      if (state.chatSearchWord) {
        chats = chats.filter(c => {
          if (c.chat_type === CHAT_TYPE.GROUP) {
            return c.chat_name.indexOf(state.chatSearchWord) !== -1
          } else {
            return c.members.some(
              m =>
                m.last_name.indexOf(state.chatSearchWord) !== -1 ||
                m.first_name.indexOf(state.chatSearchWord) !== -1
            )
          }
        })
      }
      return chats
    },
    selectedChat: state => state.selectedChat,
    newChatId: state => state.newChatId,
    chatSearchWord: state => state.chatSearchWord,
    selectedChatType: state => state.selectedChatType,
    selectedChatTypeName: state => {
      switch (state.selectedChatType) {
        case CHAT_TYPE.GROUP:
          return CHAT_TYPE_NAME.GROUP
        case CHAT_TYPE.INDIVIDUAL:
          return CHAT_TYPE_NAME.INDIVIDUAL
        default:
          return CHAT_TYPE_NAME.ALL
      }
    }
  },
  actions: {
    [FETCH_CHATS]: async (
      { commit, dispatch },
      loadingType = LOADING_TYPE_CHAT
    ) => {
      await http
        .get(ENDPOINT_CHATS, {}, loadingType)
        .then(response => {
          commit(FETCH_CHATS, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [POST_CHAT]: async ({ commit, dispatch }, params) => {
      await http
        .post(ENDPOINT_CHAT, params, LOADING_TYPE_REGISTER_CHAT)
        .then(response => {
          commit(POST_CHAT, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [PUT_CHAT]: async ({ dispatch }, params) => {
      await http
        .put(ENDPOINT_CHAT, params, LOADING_TYPE_REGISTER_CHAT)
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [PATCH_CHAT]: async ({ dispatch }, params) => {
      await http
        .patch(ENDPOINT_CHAT, params, LOADING_TYPE_PATCH_CHAT)
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [DELETE_CHAT]: async ({ dispatch }, params) => {
      await http
        .del(ENDPOINT_CHAT, params, LOADING_TYPE_DELETE_CHAT)
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [PUT_CHAT_MEMBERS]: async ({ dispatch }, params) => {
      await http
        .put(ENDPOINT_CHAT_MEMBERS, params, LOADING_TYPE_REGISTER_CHAT)
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [PATCH_CHAT_MEMBER]: async ({ dispatch }, params) => {
      await http
        .patch(ENDPOINT_CHAT_MEMBER, params, LOADING_TYPE_STAFF_CHATS)
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [PUT_CHAT_OWNER]: async ({ dispatch }, params) => {
      await http
        .put(ENDPOINT_CHAT_OWNER, params, LOADING_TYPE_PUT_CHAT_OWNER)
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [INPUT_CHAT_SEARCH_WORD]({ commit }, payload) {
      commit(INPUT_CHAT_SEARCH_WORD, payload)
    },
    [CLEAR_CHAT_SEARCH_WORD]({ commit }) {
      commit(CLEAR_CHAT_SEARCH_WORD)
    },
    [SELECT_CHAT_TYPE]({ commit }, payload) {
      commit(SELECT_CHAT_TYPE, payload)
    },
    [SET_SELECTED_CHAT]({ commit }, payload) {
      commit(SET_SELECTED_CHAT, payload)
    }
  }
}
