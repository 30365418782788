<template src="./Login.html" />
<style lang="scss" scoped src="./Login.scss"></style>
<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { FETCH_ME, POST_LOGIN, POST_USER_REGISTER } from '@/store/types'
import LoginRight from '@/compornents/LoginRight'
import '@/comporsables/useVeeValidate'
import { COPY_RIGHT, USER_STATUS } from '@/constants/constant'
import { prepareApp } from '@/utils/common'

export default defineComponent({
  name: 'Login',
  title() {
    return 'ログイン'
  },
  components: {
    LoginRight
  },
  setup(_, { root }) {
    if (root.$store.getters.me && root.$store.getters.me.is_login) {
      root.$router.push({ name: 'home' })
    }

    const isSp = computed(() => root.$store.getters.isSp)

    const selectedTab = ref('tab1')

    // ログイン
    const loginForm = reactive({
      email: '',
      password: ''
    })

    const me = computed(() => root.$store.getters.me)
    const isLoginError = ref(false)
    const login = () => {
      root.$store
        .dispatch(POST_LOGIN, loginForm)
        .then(() => {
          root.$store.dispatch(FETCH_ME).then(() => {
            // アプリにユーザーIDを渡す
            prepareApp(me.value.user_id)
            if (me.value.status === USER_STATUS.TEMPORARY) {
              root.$router.push({
                name: 'profile_register',
                params: { step: 1 }
              })
            } else {
              root.$router.push({ name: 'home' })
            }
          })
        })
        .catch(() => {
          isLoginError.value = true
        })
    }

    // 会員登録
    const registerForm = reactive({
      email: '',
      password: ''
    })

    const isRegisterError = ref(false)
    const register = () => {
      root.$store
        .dispatch(POST_USER_REGISTER, registerForm)
        .then(() => {
          root.$router.push({ name: 'user_registered' })
        })
        .catch(() => {
          isRegisterError.value = true
        })
    }

    const canLogin = (touched, invalid) => {
      if (!touched && loginForm.email && loginForm.password && invalid) {
        return true
      }

      return !invalid
    }

    const loading = computed(() => root.$store.getters.loading)

    return {
      loading,
      isSp,
      selectedTab,
      login,
      register,
      loginForm,
      registerForm,
      isLoginError,
      isRegisterError,
      canLogin,
      COPY_RIGHT
    }
  }
})
</script>
