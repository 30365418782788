<template src="./ScheduleRegisterModal.html" />
<style lang="scss" scoped src="./ScheduleRegisterModal.scss"></style>
<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import { formatDate, getToday } from '@/utils/date'
import {
  FETCH_CHATS,
  POST_SCHEDULE,
  PUT_SCHEDULE,
  SELECT_CHAT_TYPE
} from '@/store/types'
import { CHAT_TYPE, SCHEDULE_COLORS } from '@/constants/constant'
import { getWindowHeight } from '@/utils/window'

export default defineComponent({
  name: 'ScheduleRegisterModal',
  props: {
    showCreateScheduleDialog: {
      type: Boolean,
      require: true
    },
    registerEvent: {
      type: Object | String,
      default: ''
    },
    selectedSchedule: {
      type: Object,
      default: undefined
    }
  },
  setup(props, { emit, root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const me = computed(() => root.$store.getters.me)
    const showChooseColor = ref(false)
    const selectedColor = computed(() => SCHEDULE_COLORS[form.color])
    const isEdit = computed(() => props.selectedSchedule !== undefined)

    const form = reactive({
      schedule_id: 0,
      start_date: props.registerEvent
        ? formatDate(props.registerEvent.start, 'YYYY-MM-DD')
        : getToday(),
      start_time: props.registerEvent
        ? formatDate(props.registerEvent.start, 'HH:mm')
        : '00:00',
      end_date: props.registerEvent
        ? formatDate(props.registerEvent.start, 'YYYY-MM-DD')
        : getToday(),
      end_time: props.registerEvent
        ? formatDate(props.registerEvent.end, 'HH:mm')
        : '00:00',
      is_all_day: props.registerEvent ? Number(props.registerEvent.allDay) : 1,
      title: '',
      memo: '',
      place: '',
      color: 1,
      user_ids: []
    })
    if (props.selectedSchedule) {
      if (props.selectedSchedule.is_readonly) {
        emit('close')
      }
      form.schedule_id = props.selectedSchedule.schedule_id
      form.start_date = props.selectedSchedule.start_date
      form.start_time = props.selectedSchedule.start_time
      form.end_date = props.selectedSchedule.end_date
      form.end_time = props.selectedSchedule.end_time
      form.is_all_day = props.selectedSchedule.allDay
      form.title = props.selectedSchedule.title
      form.place = props.selectedSchedule.place
      form.memo = props.selectedSchedule.memo
      form.color = props.selectedSchedule.colorId
      form.user_ids = props.selectedSchedule.user_ids
    }

    const userList = computed(() =>
      root.$store.getters.contacts.map(c => ({
        label: c.last_name + ' ' + c.first_name,
        value: c.user_id
      }))
    )

    const isValidTerm = computed(() => {
      if (form.is_all_day) {
        return true
      }

      if (form.start_date > form.end_date) {
        return false
      }

      if (form.start_date < form.end_date) {
        return true
      }

      if (form.start_date === form.end_date) {
        return form.start_time <= form.end_time
      }

      return form.end_time <= form.start_time
    })

    const registerSchedule = () => {
      const param = {
        ...form,
        start_datetime: form.start_date + ' ' + form.start_time,
        end_datetime: form.is_all_day
          ? form.start_date + ' ' + form.start_time
          : form.end_date + ' ' + form.end_time,
        is_all_day: Number(form.is_all_day),
        user_ids: [...form.user_ids, me.value.user_id]
      }
      if (isEdit.value) {
        root.$store.dispatch(PUT_SCHEDULE, param).then(() => {
          emit('close')
          emit('fetchSchedules')
        })
      } else {
        root.$store.dispatch(POST_SCHEDULE, param).then(() => {
          emit('close')
          emit('fetchSchedules', true)
        })
      }
    }

    const loading = computed(() => root.$store.getters.loading)
    const canPost = computed(() => form.title !== '' && isValidTerm.value)

    const chats = computed(() => root.$store.getters.chats)
    root.$store.dispatch(SELECT_CHAT_TYPE, CHAT_TYPE.GROUP)

    const shareUsers = ref([])
    root.$store.dispatch(FETCH_CHATS).then(() => {
      const groupMembers = chats.value
        .filter(c => c.chat_type === CHAT_TYPE.GROUP)
        .filter(
          c => c.members.filter(m => m.user_id !== me.value.user_id).length > 0
        )
        .map(c => ({
          label: c.chat_name,
          options: c.members
            .filter(m => m.user_id !== me.value.user_id)
            .map(m => ({
              label: m.last_name + ' ' + m.first_name,
              value: m.user_id
            }))
        }))
      shareUsers.value = [
        {
          label: 'コンタクトメンバー',
          options: userList.value
        },
        ...groupMembers
      ]
    })

    return {
      loading,
      isSp,
      showChooseColor,
      form,
      userList,
      selectedColor,
      registerSchedule,
      isEdit,
      canPost,
      windowHeight: getWindowHeight(50),
      shareUsers,
      isValidTerm
    }
  }
})
</script>
