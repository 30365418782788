<template src="./ManagePrice.html" />
<style lang="scss" scoped src="./ManagePrice.scss"></style>
<script>
import { computed, defineComponent, ref } from '@vue/composition-api'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import ManageMenu from '@/compornents/ManageMenu/ManageMenu'
import { getLastMonth, getThisYear } from '@/utils/date'
import {
  GET_STAFF_MONTHLY_USAGE,
  GET_STAFF_MONTHLY_USAGE_DETAIL
} from '@/store/types'
import { CHAT_STATUS, STAFF_HISTORY_STATUS } from '@/constants/constant'

export default defineComponent({
  name: 'ManageStaff',
  title() {
    return 'スタッフ招待 - 介護事業者管理画面'
  },
  components: {
    HeaderRight,
    AvatarImg,
    ManageMenu
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const isManage = computed(() => root.$store.getters.isManage)
    // SPまたは事業管理者以外の場合はホームへ
    if (isSp.value || !isManage.value) {
      root.$router.push({ name: 'home' })
    }

    const selectedYear = ref(getThisYear())
    const selectedMonth = ref(parseInt(getLastMonth()))

    const getStaffMonthlyUsage = () => {
      root.$store.dispatch(GET_STAFF_MONTHLY_USAGE, {
        payment_date: selectedYear.value + '-' + selectedMonth.value
      })
    }
    getStaffMonthlyUsage()

    const page = ref(1)
    const getStaffMonthlyUsageDetail = () => {
      root.$store.dispatch(GET_STAFF_MONTHLY_USAGE_DETAIL, {
        page: page.value
      })
    }
    getStaffMonthlyUsageDetail()

    const staffMonthlyUsage = computed(
      () => root.$store.getters.staff_monthly_usage
    )
    const staffMonthlyUsageDetail = computed(
      () => root.$store.getters.staff_monthly_usage_detail
    )

    const endYear = getThisYear()
    let yearList = []
    for (let i = 2021; i <= endYear; i = i + 1) {
      yearList = [...yearList, i]
    }

    const basicFeeTotal = computed(
      () =>
        staffMonthlyUsage.value.basic_fee *
        staffMonthlyUsage.value.payment_staff_count
    )
    const groupChatFeeTotal = computed(
      () =>
        staffMonthlyUsage.value.group_chat_fee *
        staffMonthlyUsage.value.payment_chat_count
    )
    const total = computed(() => basicFeeTotal.value + groupChatFeeTotal.value)
    const tax = computed(() => Math.round(total.value * 0.1))

    const convertLabel = status => {
      switch (status) {
        case STAFF_HISTORY_STATUS.INVITING: // 招待中
          return 'label02'
        case STAFF_HISTORY_STATUS.PARTICIPATED: // 参加済
          return 'label03'
        case CHAT_STATUS.SLEEP: // 休眠中
          return 'label04'
        case STAFF_HISTORY_STATUS.LEAVE: // 退室済
          return 'label05'
        default:
          return ''
      }
    }

    const nextPage = () => {
      if (page.value === getStaffMonthlyUsage.value.total_page) {
        return
      }
      page.value++
      getStaffMonthlyUsageDetail()
    }

    const prevPage = () => {
      if (page.value === 1) {
        return
      }
      page.value--
      getStaffMonthlyUsageDetail()
    }

    const showSummaryGroup = ref(false)
    const summaryGroupChats = ref([])
    const openSummaryGroup = user_id => {
      showSummaryGroup.value = true
      summaryGroupChats.value = staffMonthlyUsageDetail.value.staffs.find(
        s => s.user_id === user_id
      ).chats
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      getStaffMonthlyUsage,
      getStaffMonthlyUsageDetail,
      convertLabel,
      yearList,
      selectedYear,
      selectedMonth,
      staffMonthlyUsage,
      staffMonthlyUsageDetail,
      basicFeeTotal,
      groupChatFeeTotal,
      total,
      tax,
      nextPage,
      prevPage,
      page,
      openSummaryGroup,
      showSummaryGroup,
      summaryGroupChats
    }
  }
})
</script>
