<template src="./Other.html" />
<style lang="scss" scoped src="./Other.scss"></style>
<script>
import { computed, defineComponent, reactive, ref } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import { POST_LOGOUT, RESET_PASSWORD } from '@/store/types'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import BusinessProfile from '@/compornents/BusinessProfile/BusinessProfile'
import { getWindowHeight } from '@/utils/window'

export default defineComponent({
  name: 'Other',
  title() {
    return 'その他'
  },
  components: {
    HeaderRight,
    AvatarImg,
    BusinessProfile
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    const layer1 = computed(() => root.$route.params.layer1)
    const layer2 = computed(() => root.$route.params.layer2)

    const logout = () => {
      root.$store.dispatch(POST_LOGOUT).then(() => {
        location.reload()
      })
    }

    const showLogoutConfirm = ref(false)

    const passwordForm = reactive({
      password: '',
      password_confirmation: ''
    })

    const resetPassword = () => {
      root.$store.dispatch(RESET_PASSWORD, passwordForm).then(() => logout())
    }

    return {
      loading: computed(() => root.$store.getters.loading),
      isSp: computed(() => root.$store.getters.isSp),
      isManage: computed(() => root.$store.getters.isManage),
      me,
      logout,
      layer1,
      layer2,
      showLogoutConfirm,
      passwordForm,
      resetPassword,
      windowHeight: getWindowHeight(74)
    }
  }
})
</script>
