<template src="./BusinessProfile.html" />
<style lang="scss" scoped src="./BusinessProfile.scss"></style>
<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch
} from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import { COPY_RIGHT, PAYMENTS, PREFECTURE_LIST } from '@/constants/constant'
import { GET_POST_CODE, POST_BUSINESS_PROFILE } from '@/store/types'

export default defineComponent({
  name: 'BusinessProfile',
  title() {
    return '決済代行情報'
  },
  props: {
    isEdit: {
      type: Boolean
    }
  },
  setup(props, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const { me } = useMe(root)
    if (!me.is_login) {
      root.$router.push({ name: 'login' })
    }
    const business_profile = computed(
      () => root.$store.getters.business_profile
    )

    const step = ref(1)

    let initForm = null
    if (props.isEdit) {
      initForm = {
        office_number: business_profile.value.office_number,
        company_name: business_profile.value.company_name,
        company_name_kana: business_profile.value.company_name_kana,
        delegation_last_name: business_profile.value.delegation_last_name,
        delegation_first_name: business_profile.value.delegation_first_name,
        delegation_last_name_kana:
          business_profile.value.delegation_last_name_kana,
        delegation_first_name_kana:
          business_profile.value.delegation_first_name_kana,
        postcode: business_profile.value.postcode,
        prefecture_id: business_profile.value.prefecture_id,
        city: business_profile.value.city,
        block: business_profile.value.block,
        building: business_profile.value.building,
        branch_name: business_profile.value.branch_name,
        personal_last_name: business_profile.value.personal_last_name,
        personal_first_name: business_profile.value.personal_first_name,
        personal_last_name_kana: business_profile.value.personal_last_name_kana,
        personal_first_name_kana:
          business_profile.value.personal_first_name_kana,
        business_phone: business_profile.value.business_phone,
        fax_phone: business_profile.value.fax_phone,
        mobile_phone: business_profile.value.mobile_phone,
        email: business_profile.value.email,
        url1: business_profile.value.url1,
        url2: business_profile.value.url2,
        url3: business_profile.value.url3,
        payment: business_profile.value.payment
      }
    } else {
      initForm = {
        office_number: me.office_number,
        company_name: me.organization_name,
        company_name_kana: '',
        delegation_last_name: me.last_name,
        delegation_first_name: me.first_name,
        delegation_last_name_kana: me.last_name_kana,
        delegation_first_name_kana: me.first_name_kana,
        postcode: me.postcode,
        prefecture_id: me.prefecture_id,
        city: me.city,
        block: me.block,
        building: me.building,
        branch_name: me.branch_name,
        personal_last_name: '',
        personal_first_name: '',
        personal_last_name_kana: '',
        personal_first_name_kana: '',
        business_phone: me.business_phone,
        fax_phone: '',
        mobile_phone: me.mobile_phone,
        email: me.email,
        url1: me.homepage_url,
        url2: '',
        url3: '',
        payment: '1'
      }
    }
    const form = reactive(initForm)

    const post = () => {
      root.$store
        .dispatch(POST_BUSINESS_PROFILE, form)
        .then(() => location.reload())
        .catch(() => undefined)
    }

    const postCodeIsInvalid = ref(false)
    const selectedPrefecture = computed(() =>
      form.prefecture_id
        ? PREFECTURE_LIST.find(p => p.value === parseInt(form.prefecture_id))
            .label
        : ''
    )

    watch(
      () => form.postcode,
      v =>
        root.$store
          .dispatch(GET_POST_CODE, v)
          .then(() => {
            postCodeIsInvalid.value = false
            form.prefecture_id = root.$store.getters.address.prefecture_id
            form.city = root.$store.getters.address.city
            form.block = root.$store.getters.address.block
          })
          .catch(() => {
            postCodeIsInvalid.value = true
          })
    )

    const selectedPayment = computed(
      () =>
        // form.payment
        //   ? PAYMENTS.find(p => p.value === form.payment + '').label
        //   : ''
        PAYMENTS.find(p => p.value === form.payment + '').label
    )

    const loading = computed(() => root.$store.getters.loading)

    const next = () => {
      step.value = 2
      window.scroll(0, 0)
    }

    const prev = () => {
      step.value = 1
      window.scroll(0, 0)
    }

    const showInvalidErrorMsg = ref(false)

    return {
      loading,
      isSp,
      PREFECTURE_LIST,
      step,
      form,
      post,
      postCodeIsInvalid,
      selectedPrefecture,
      PAYMENTS,
      selectedPayment,
      COPY_RIGHT,
      next,
      prev,
      showInvalidErrorMsg
    }
  }
})
</script>
