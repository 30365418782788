import * as http from '@/utils/api'
import {
  FETCH_ME,
  GET_USER_IDENTIFICATION,
  POST_LOGIN,
  POST_LOGOUT,
  POST_USER_IDENTIFICATION,
  RESET_PASSWORD,
  SET_IS_SP,
  SET_IS_TABLET
} from './types'
import {
  ENDPOINT_LOGIN,
  ENDPOINT_LOGOUT,
  ENDPOINT_ME,
  ENDPOINT_RESET_PASSWORD,
  ENDPOINT_SANCTUM_CSRF_COOKIE,
  ENDPOINT_USER_IDENTIFICATION
} from '@/constants/endpoint'
import {
  LOADING_TYPE_LOGIN,
  LOADING_TYPE_LOGOUT,
  LOADING_TYPE_RESET_PASSWORD,
  USER_CATEGORY
} from '@/constants/constant'
import { stopBeams } from '@/utils/beams'
import { isChrome } from '@/utils/common'

export default {
  state: {
    me: {},
    isSp: false,
    isTablet: false
  },
  mutations: {
    [FETCH_ME](state, responseData) {
      state.me = { ...state.me, ...responseData }
    },
    [SET_IS_SP](state, payload) {
      state.isSp = payload
    },
    [SET_IS_TABLET](state, payload) {
      state.isTablet = payload
    }
  },
  getters: {
    me: state => state.me,
    useFamilyChat: state =>
      [USER_CATEGORY.MANAGER, USER_CATEGORY.FAMILY].includes(
        state.me.category_id
      ),
    isSp: state => state.isSp,
    isTablet: state => state.isTablet,
    isManage: state =>
      state.me.is_staff === 0 &&
      [
        USER_CATEGORY.BUSINESS,
        USER_CATEGORY.MEDICAL,
        USER_CATEGORY.OTHER
      ].includes(state.me.category_id)
  },
  actions: {
    [POST_LOGIN]: async ({ dispatch }, params) => {
      await http.get(ENDPOINT_SANCTUM_CSRF_COOKIE, {}, LOADING_TYPE_LOGIN)
      await http
        .post(ENDPOINT_LOGIN, params, LOADING_TYPE_LOGIN)
        .catch(error => {
          console.log(error)
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_LOGOUT]: async ({ dispatch }) => {
      if (isChrome()) {
        stopBeams()
      }

      await http.post(ENDPOINT_LOGOUT, {}, LOADING_TYPE_LOGOUT).catch(error => {
        console.log(error)
        http.handleError(dispatch, error)
      })
    },
    [FETCH_ME]: async ({ commit, dispatch, state }) => {
      await http
        .get(ENDPOINT_ME)
        .then(response => {
          commit(FETCH_ME, response.data)
          if (!response.data.is_login) {
            http.handle401()
          }
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })

      if (state.me.category_id === USER_CATEGORY.MANAGER) {
        await dispatch(GET_USER_IDENTIFICATION)
      }
    },
    [SET_IS_SP]: ({ commit }, payload) => {
      commit(SET_IS_SP, payload)
    },
    [SET_IS_TABLET]: ({ commit }, payload) => {
      commit(SET_IS_TABLET, payload)
    },
    [RESET_PASSWORD]: async ({ dispatch }, params) => {
      await http
        .patch(ENDPOINT_RESET_PASSWORD, params, LOADING_TYPE_RESET_PASSWORD)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    },
    [POST_USER_IDENTIFICATION]: async ({ dispatch }, params) => {
      await http.post(ENDPOINT_USER_IDENTIFICATION, params).catch(error => {
        http.handleError(dispatch, error)
        throw new Error()
      })
    }
  }
}
