<template src="./SidebarNav.html" />
<style lang="scss" scoped src="./SidebarNav.scss"></style>
<script>
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { ID_CHECKED_STATUS, USER_CATEGORY } from '@/constants/constant'

export default defineComponent({
  name: 'SidebarNav',
  setup(_, { root }) {
    const selectedNav = computed(() => root.$route.name)
    const chats = computed(() => root.$store.getters.chats)
    const me = computed(() => root.$store.getters.me)
    const identificationStatus = computed(
      () => root.$store.getters.identificationStatus
    )
    const unreadChatCount = ref(0)
    const isSp = computed(() => root.$store.getters.isSp)
    const isFamily = computed(
      () => root.$store.getters.me.category_id === USER_CATEGORY.FAMILY
    )

    watch(
      () => chats.value,
      v => {
        console.log('watch chats', v)
        if (v.length === 0) {
          return
        }
        if (me.value.category_id === USER_CATEGORY.MANAGER) {
          console.log('watch chats A', v)
          unreadChatCount.value = v.filter(
            c => c.unread_count > 0 || c.unread_count_family > 0
          ).length
        } else if (me.value.category_id === USER_CATEGORY.FAMILY) {
          console.log('watch chats B', v)
          unreadChatCount.value = v.filter(
            c => c.unread_count_family > 0
          ).length
        } else {
          console.log('watch chats C', v)
          unreadChatCount.value = v.filter(c => c.unread_count > 0).length
        }
      }
    )

    const makeReportMode = computed(() => root.$store.getters.makeReportMode)

    return {
      me,
      identificationStatus,
      isFamily,
      isSp,
      selectedNav,
      unreadChatCount,
      makeReportMode,
      ID_CHECKED_STATUS,
      USER_CATEGORY,
      isManage: computed(() => root.$store.getters.isManage)
    }
  }
})
</script>
