<template>
  <div class="confirmation" :class="{ error: isError }">
    {{ isError ? '認証失敗' : '認証中・・・' }}
  </div>
</template>
<style lang="scss" scoped>
.confirmation {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22px;
  color: #999;
  line-height: 50px;
  text-align: center;
  width: 100%;
  &.error {
    color: #cf3830;
  }
}
</style>
<script>
import { defineComponent, ref } from '@vue/composition-api'
import { FETCH_ME, PATCH_USER_REGISTER_CONFIRM } from '@/store/types'

export default defineComponent({
  name: 'UserConfirmation',
  title() {
    return 'ユーザー認証'
  },
  setup(_, { root }) {
    const isError = ref(false)
    root.$store
      .dispatch(PATCH_USER_REGISTER_CONFIRM, {
        confirm_id: root.$route.params.confirm_id
      })
      .then(() => {
        root.$notify({
          title: '',
          message:
            '認証に成功しました<br>ログインしてプロフィール登録して下さい',
          dangerouslyUseHTMLString: true,
          type: 'success'
        })
        root.$store.dispatch(FETCH_ME).then(() => {
          location.href = '/'
        })
      })
      .catch(() => {
        root.$notify({
          title: '',
          message: '認証に失敗しました',
          type: 'error'
        })
        location.href = '/'
      })

    return {
      isError
    }
  }
})
</script>
