<template src="./ManageMenu.html" />
<style lang="scss" scoped src="./ManageMenu.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ManageMenu',
  components: {},
  props: {},
  setup(_, { root }) {
    const page = computed(() => root.$route.name)
    return {
      page
    }
  }
})
</script>
