import * as http from '@/utils/api'
import { FETCH_BUSINESS_PROFILE, POST_BUSINESS_PROFILE } from './types'
import { ENDPOINT_BUSINESS_PROFILE } from '@/constants/endpoint'
import { LOADING_TYPE_BUSINESS_PROFILE } from '@/constants/constant'
import { isEmptyObject } from '@/utils/common'

export default {
  state: {
    business_profile: null
  },
  mutations: {
    [FETCH_BUSINESS_PROFILE](state, responseData) {
      if (!isEmptyObject(responseData)) {
        state.business_profile = responseData
      }
    }
  },
  getters: {
    business_profile: state => state.business_profile
  },
  actions: {
    [FETCH_BUSINESS_PROFILE]: async ({ commit, dispatch }) => {
      await http
        .get(ENDPOINT_BUSINESS_PROFILE, {}, LOADING_TYPE_BUSINESS_PROFILE)
        .then(response => {
          commit(FETCH_BUSINESS_PROFILE, response.data)
        })
        .catch(error => {
          http.handleError(dispatch, error)
        })
    },
    [POST_BUSINESS_PROFILE]: async ({ dispatch }, params) => {
      await http
        .post(ENDPOINT_BUSINESS_PROFILE, params, LOADING_TYPE_BUSINESS_PROFILE)
        .catch(error => {
          http.handleError(dispatch, error)
          throw new Error()
        })
    }
  }
}
