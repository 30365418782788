<template src="./Chat.html" />
<style lang="scss" scoped src="./Chat.scss"></style>
<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch
} from '@vue/composition-api'
import {
  CLEAR_CHAT_SEARCH_WORD,
  CLEAR_MESSAGES,
  CLEAR_REPORT_MESSAGES,
  DELETE_CHAT,
  DELETE_MESSAGE,
  EDIT_MESSAGE,
  FETCH_BUSINESS_PROFILE,
  FETCH_CHATS,
  FETCH_CONTACTS,
  FETCH_FILES,
  FETCH_MESSAGES,
  INPUT_CHAT_SEARCH_WORD,
  PATCH_CHAT,
  POST_MESSAGE_READ,
  REMOVE_MESSAGE,
  SELECT_CHAT_TYPE,
  SET_FAMILY_CHAT,
  SET_LAST_READ_MESSAGE_ID,
  SET_MAKE_REPORT_MODE,
  SET_SELECTED_CHAT,
  UNSET_FAMILY_CHAT
} from '@/store/types'
import Pusher from 'pusher-js'
import useMe from '@/comporsables/useMe'
import HeaderRight from '@/compornents/HeaderRight/HeaderRight'
import EditChatModal from '@/compornents/EditChatModal/EditChatModal'
import AvatarImg from '@/compornents/AvatarImg/AvatarImg'
import ProfileModal from '@/compornents/ProfileModal/ProfileModal'
import FileView from '@/compornents/FileView/FileView'
import ChatView from '@/compornents/ChatView/ChatView'
import MakeReportModal from '@/compornents/MakeReportModal/MakeReportModal'
import FileListModal from '@/compornents/FileListModal/FileListModal'
import RecordModal from '@/compornents/RecordModal/RecordModal'
import {
  CHAT_STATUS,
  CHAT_TYPE,
  FOCUSED_CHAT,
  LOADING_TYPE_FAMILY_MESSAGE,
  LOADING_TYPE_INDIVIDUAL_OR_GROUP_MESSAGE,
  LOADING_TYPE_NONE,
  MESSAGE_TYPE,
  RECORD_TYPE,
  USER_CATEGORY,
  USER_STATUS
} from '@/constants/constant'
import { getS3Url } from '@/comporsables/useAws'
import { downloadFile } from '@/utils/common'
import { getWindowHeight } from '@/utils/window'

export default defineComponent({
  name: 'Chat',
  title() {
    return 'チャット'
  },
  components: {
    HeaderRight,
    EditChatModal,
    AvatarImg,
    ProfileModal,
    FileView,
    ChatView,
    MakeReportModal,
    FileListModal,
    RecordModal
  },
  setup(_, { root }) {
    const isSp = computed(() => root.$store.getters.isSp)
    const isTablet = computed(() => root.$store.getters.isTablet)
    const showEditChatModal = ref(false)
    const { me } = useMe(root)
    const chats = computed(() => root.$store.getters.chats)
    const selectedChat = computed(() => root.$store.getters.selectedChat)
    const loading = computed(() => root.$store.getters.loading)
    const message_type = ref('')
    const page = ref(1)
    const page_family = ref(1)
    const focusedChat = ref('')
    const messages = computed(() => root.$store.getters.messages)
    const family_messages = computed(() => root.$store.getters.family_messages)
    const useFamilyChat = computed(() => root.$store.getters.useFamilyChat)
    const contacts = computed(() => root.$store.getters.contacts)

    root.$store.dispatch(FETCH_CONTACTS)

    if (me.status === USER_STATUS.TEMPORARY) {
      root.$router.push({ name: 'profile_register', params: { step: 1 } })
    }

    // 決済代行情報登録済チェック
    const isManage = computed(() => root.$store.getters.isManage)
    if (isManage.value) {
      root.$store.dispatch(FETCH_BUSINESS_PROFILE).then(() => {
        if (!root.$store.getters.business_profile) {
          root.$router.push({ name: 'business_profile_register' })
        }
      })
    }

    const app_key = computed(() => process.env.VUE_APP_PUSHER_APP_KEY)
    Pusher.logToConsole = false
    const pusher = new Pusher(app_key.value, {
      cluster: 'ap3'
    })

    const showCommonFiles = ref(true)
    showCommonFiles.value = !isSp.value
    const showFamilyChat = computed(() => root.$store.getters.show_family_chat)
    const messageTypeIsFamily = computed(
      () => message_type.value === MESSAGE_TYPE.FAMILY
    )

    watch(
      // ヘッダーのファミリーチャットスイッチ監視
      () => showFamilyChat.value,
      v => {
        if (v) {
          // ONにしたとき
          message_type.value = MESSAGE_TYPE.FAMILY
          initFamilyChat()
          focusedChat.value = FOCUSED_CHAT.FAMILY
          setMakeReportMode(false)
          if (isSp.value) {
            return
          }
          showCommonFiles.value = false
        } else {
          // OFFにしたとき
          message_type.value = MESSAGE_TYPE.CARE_MEMBER

          pusher.unsubscribe(
            'chat-' + selectedChat.value.chat_id + '-' + message_type.value
          )
          root.$store.dispatch(CLEAR_MESSAGES, true)
          page_family.value = 0
          focusedChat.value = FOCUSED_CHAT.INDIVIDUAL_OR_GROUP
          if (isSp.value) {
            return
          }
          showCommonFiles.value = true
        }
      }
    )

    watch(
      () => messages.value.length,
      (aft, pre) => {
        if (aft > pre) {
          console.log('watch scrollToBottom')
          setTimeout(scrollToBottom, 500)
        }
      }
    )

    const initChat = chat => {
      root.$store.dispatch(SET_SELECTED_CHAT, chat)

      // 個人チャット
      if (selectedChat.value.chat_type === CHAT_TYPE.INDIVIDUAL) {
        message_type.value = MESSAGE_TYPE.INDIVIDUAL
        fetchFiles()
      } else {
        // グループチャット
        if (me.category_id === USER_CATEGORY.FAMILY) {
          message_type.value = MESSAGE_TYPE.FAMILY
        } else {
          message_type.value = MESSAGE_TYPE.CARE_MEMBER
        }
      }

      subscribeChannel()
      fetchMessages(LOADING_TYPE_INDIVIDUAL_OR_GROUP_MESSAGE)
    }
    const initFamilyChat = () => {
      message_type.value = MESSAGE_TYPE.FAMILY
      subscribeChannel()
      fetchMessages(LOADING_TYPE_FAMILY_MESSAGE)
    }

    const fetchChats = () => {
      if (root.$route.params.chat_type) {
        root.$store.dispatch(SELECT_CHAT_TYPE, root.$route.params.chat_type)
        root.$store.dispatch(CLEAR_MESSAGES)
      }
      root.$store.dispatch(CLEAR_MESSAGES, messageTypeIsFamily.value)
      root.$store.dispatch(FETCH_CHATS).then(() => {
        if (root.$route.params.chat_id) {
          const chat = chats.value.find(
            c => c.chat_id === parseInt(root.$route.params.chat_id)
          )
          if (chat) {
            initChat(chat)
          }
          return
        }

        if (isSp.value) {
          return
        }

        if (chats.value.length > 0) {
          initChat(chats.value[0])
        }
      })
    }
    fetchChats()
    // if (chats.value.length === 0) {
    // }

    const lastReadMessageId = computed(() =>
      messageTypeIsFamily.value ||
      (me.category_id === USER_CATEGORY.FAMILY &&
        selectedChat.chat_type === CHAT_TYPE.GROUP)
        ? root.$store.getters.last_read_family_message_id
        : root.$store.getters.last_read_message_id
    )

    const postMessageRead = message_id => {
      if (message_id <= lastReadMessageId.value) {
        return
      }
      root.$store.dispatch(POST_MESSAGE_READ, {
        chat_id: selectedChat.value.chat_id,
        user_id: me.user_id,
        message_id: message_id,
        message_type: message_type.value
      })
    }

    const scrollToBottom = () => {
      if (isFetchNextMessages.value) {
        console.log('ここか')
        return
      }

      let container =
        focusedChat.value === FOCUSED_CHAT.FAMILY
          ? '#familyMessageViewContainer > .divScroll'
          : '#messageViewContainer > .divScroll'

      if (isSp.value) {
        container =
          focusedChat.value === FOCUSED_CHAT.FAMILY
            ? '#familyMessageViewContainer'
            : '#messageViewContainer'
      }

      root.$scrollTo('#bottom', 0, {
        container: container,
        force: true,
        offset: 0,
        x: false,
        y: true
      })
    }

    const deleteMessageId = ref(0)
    const showDeleteMessageConfirm = ref(false)
    const openDeleteMessageConfirm = messageId => {
      showDeleteMessageConfirm.value = true
      deleteMessageId.value = messageId
    }
    const deleteMessage = () => {
      showDeleteMessageConfirm.value = false
      root.$store.dispatch(REMOVE_MESSAGE, {
        message_id: deleteMessageId.value,
        isFamily: messageTypeIsFamily.value
      })
      root.$store.dispatch(DELETE_MESSAGE, {
        message_id: deleteMessageId.value
      })
    }

    const fetchMessages = async loading_type => {
      await root.$store
        .dispatch(FETCH_MESSAGES, {
          param: {
            chat_id: selectedChat.value.chat_id,
            message_type: message_type.value,
            page: messageTypeIsFamily.value ? page_family.value : page.value
          },
          loading_type
        })
        .then(() => {
          if (messageTypeIsFamily.value) {
            if (family_messages.value.length > 0) {
              postMessageRead(
                family_messages.value[family_messages.value.length - 1]
                  .message_id
              )
            }
          } else {
            if (messages.value.length > 0) {
              postMessageRead(
                messages.value[messages.value.length - 1].message_id
              )
            }
          }
          scrollToBottom()
        })
    }

    const isFetchNextMessages = ref(false)
    const fetchNextMessages = isFamily => {
      isFetchNextMessages.value = true
      isFamily || me.category_id === USER_CATEGORY.FAMILY
        ? page_family.value++
        : page.value++
      fetchMessages(
        isFamily
          ? LOADING_TYPE_FAMILY_MESSAGE
          : LOADING_TYPE_INDIVIDUAL_OR_GROUP_MESSAGE
      ).then(() => {
        const new_message_id = computed(() =>
          isFamily
            ? root.$store.getters.new_family_message_id
            : root.$store.getters.new_message_id
        )

        setTimeout(() => {
          let container =
            focusedChat.value === FOCUSED_CHAT.FAMILY
              ? '#familyMessageViewContainer > .divScroll'
              : '#messageViewContainer > .divScroll'

          if (isSp.value) {
            container =
              focusedChat.value === FOCUSED_CHAT.FAMILY
                ? '#familyMessageViewContainer'
                : '#messageViewContainer'
          }

          root.$scrollTo('#message' + new_message_id.value, 0, {
            container: container,
            force: true,
            offset: 0,
            x: false,
            y: true
          }),
            500
        })
      })
    }

    const userChannel = pusher.subscribe('user-' + me.user_id)
    userChannel.bind('UPDATE_CHAT', () => {
      root.$store.dispatch(FETCH_CHATS, LOADING_TYPE_NONE)
    })

    const subscribeChannel = () => {
      console.log(
        'subscribeChannel: ',
        'chat-' + selectedChat.value.chat_id + '-' + message_type.value
      )
      const channel = pusher.subscribe(
        'chat-' + selectedChat.value.chat_id + '-' + message_type.value
      )
      console.log('channels:', Object.keys(pusher.channels.channels))
      console.log(selectedChat.value.chat_id)

      channel.bind('POST_MESSAGE', data => {
        console.log(
          'POST_MESSAGE',
          data.payload.chat_id,
          data.payload,
          selectedChat.value.chat_id
        )
        console.log('channels:', Object.keys(pusher.channels.channels))
        if (data.payload.chat_id !== selectedChat.value.chat_id) {
          return
        }
        // root.$store.dispatch(ADD_MESSAGE, {
        //   message: data.payload,
        //   isFamily: messageTypeIsFamily.value
        // })

        fetchMessages(LOADING_TYPE_NONE)

        if (data.payload.user_id === me.user_id) {
          console.log('自分のメッセージ送信の通知')
          // 自分のメッセージ送信の通知の場合は何もしない
          return
        }
        setTimeout(() => {
          postMessageRead(data.payload.message_id)
          root.$store.dispatch(SET_LAST_READ_MESSAGE_ID, {
            message_id: data.payload.message_id,
            isFamily: messageTypeIsFamily.value
          })
        }, 2000)
      })
      channel.bind('PUT_MESSAGE', data => {
        console.log('channels:', Object.keys(pusher.channels.channels))
        console.log('PUT_MESSAGE', data)

        root.$store.dispatch(EDIT_MESSAGE, {
          message: data.payload,
          isFamily: messageTypeIsFamily.value
        })
        root.$store.dispatch(FETCH_CHATS, LOADING_TYPE_NONE)
      })
      channel.bind('DELETE_MESSAGE', data => {
        console.log('channels:', Object.keys(pusher.channels.channels))
        console.log('DELETE_MESSAGE', data)

        root.$store.dispatch(REMOVE_MESSAGE, {
          message_id: data.payload,
          isFamily: messageTypeIsFamily.value
        })
      })
    }

    const selectChat = chat => {
      if (parseInt(root.$route.params.chat_id) === chat.chat_id) {
        return
      }
      console.log('selectChat!!!')
      console.log('channels:', Object.keys(pusher.channels.channels))
      Object.keys(pusher.channels.channels).forEach(c => {
        console.log(c)
        if (c.startsWith('chat-')) {
          console.log('channel', pusher.channels.channels[c])
          console.log('unsubscribe', c)
          pusher.unsubscribe(c)
        }
      })

      root.$store.dispatch(UNSET_FAMILY_CHAT)
      root.$store.dispatch(CLEAR_MESSAGES, messageTypeIsFamily.value)

      root.$router.push({
        name: 'chat',
        params: { chat_type: selectedChatType.value, chat_id: chat.chat_id }
      })

      page.value = 1
      page_family.value = 1

      initChat(chat)
    }

    const backToChats = () => {
      root.$store.dispatch(SET_SELECTED_CHAT, null)

      console.log('channels:', Object.keys(pusher.channels.channels))
      if (root.$route.name === 'chat') {
        root.$router.push({
          name: 'chats',
          params: { chat_type: selectedChatType.value }
        })
        // .then(() => location.reload())
        root.$router.push({
          name: 'chat',
          params: { chat_type: selectedChatType.value, chat_id: 0 }
        })
      }
    }

    const showMenu = ref(false)
    const showMenuIsTop = ref(false)
    const toggleShowMenu = e => {
      showMenu.value = !showMenu.value
      if (!showMenu.value) {
        showMenuIsTop.value = false
      }
      if (showMenu.value && window.innerHeight - e.clientY < 260) {
        showMenuIsTop.value = true
      }
    }

    const editChat = ref(null)
    const openEditChatModal = chat => {
      editChat.value = chats.value.find(c => c.chat_id === chat.chat_id)
      showEditChatModal.value = true
      showMenu.value = false
    }

    const clear = () => {
      showMenu.value = false
    }

    const closeEditChatModal = () => {
      showEditChatModal.value = false
      editChat.value = null
    }

    const delete_chat_id = ref(0)
    const showDeleteChatConfirm = ref(false)
    const openDeleteChatConfirm = chat_id => {
      showMenu.value = false
      showDeleteChatConfirm.value = true
      delete_chat_id.value = chat_id
    }

    const sleepChatId = ref(0)
    const showSleepChatConfirm = ref(false)
    const openSleepChatConfirm = chat_id => {
      showMenu.value = false
      showSleepChatConfirm.value = true
      sleepChatId.value = chat_id
    }

    const showUnSleepChatConfirm = ref(false)
    const openUnSleepChatConfirm = chat_id => {
      showMenu.value = false
      showUnSleepChatConfirm.value = true
      sleepChatId.value = chat_id
    }

    const deleteChat = () => {
      root.$store
        .dispatch(DELETE_CHAT, {
          data: { chat_id: delete_chat_id.value }
        })
        .then(() => {
          showDeleteChatConfirm.value = false
          delete_chat_id.value = false
          if (isSp.value) {
            root.$store.dispatch(SET_SELECTED_CHAT, null)
            root.$router
              .push({
                name: 'chats',
                params: { chat_type: selectedChatType.value }
              })
              .then(() => fetchChats())
          } else {
            fetchChats()
          }
        })
    }

    const sleepChat = status => {
      root.$store
        .dispatch(PATCH_CHAT, {
          chat_id: sleepChatId.value,
          status: status
        })
        .then(() => {
          showSleepChatConfirm.value = false
          showUnSleepChatConfirm.value = false
          sleepChatId.value = false
          if (isSp.value) {
            root.$store.dispatch(SET_SELECTED_CHAT, null)
            root.$router
              .push({
                name: 'chats',
                params: { chat_type: selectedChatType.value }
              })
              .then(() => fetchChats())
          } else {
            fetchChats()
          }
        })
    }

    const showProfileModal = ref(false)
    const showProfileUser = ref(null)
    const openProfileModal = user => {
      showProfileUser.value = contacts.value.find(
        c => c.user_id === user.user_id
      )
      showProfileModal.value = true
      showMenu.value = false
    }

    const chatSearchWord = computed(() => root.$store.getters.chatSearchWord)
    const inputChatSearchWord = e => {
      root.$store.dispatch(INPUT_CHAT_SEARCH_WORD, e.target.value)
    }
    const clearChatSearchWord = () => {
      root.$store.dispatch(CLEAR_CHAT_SEARCH_WORD)
    }

    // チャットタイプ選択プルダウン
    const showChatSelect = ref(false)

    const chat_type = root.$route.params.chat_type
    if (chat_type) {
      root.$store.dispatch(SELECT_CHAT_TYPE, chat_type)
    }
    const selectedChatType = computed(
      () => root.$store.getters.selectedChatType
    )
    const selectedChatTypeName = computed(
      () => root.$store.getters.selectedChatTypeName
    )
    const selectChatType = chatType => {
      root.$store.dispatch(UNSET_FAMILY_CHAT)
      root.$store.dispatch(CLEAR_MESSAGES, messageTypeIsFamily.value)
      root.$store.dispatch(SELECT_CHAT_TYPE, chatType)
      if (!isSp.value) {
        if (selectedChat.value) {
          pusher.unsubscribe(
            'chat-' + selectedChat.value.chat_id + '-' + message_type.value
          )
        }
        if (chats.value.length > 0) {
          const chat = chats.value[0]
          initChat(chat)
          root.$router.push({
            name: 'chat',
            params: { chat_type: chatType, chat_id: chat.chat_id }
          })
        }
      } else {
        root.$router.push({ name: 'chats', params: { chat_type: chatType } })
      }
      showChatSelect.value = false
    }

    const changeFocusedChat = payload => {
      focusedChat.value = payload
      if (focusedChat.value === FOCUSED_CHAT.FAMILY) {
        message_type.value = MESSAGE_TYPE.FAMILY
      } else if (selectedChat.value.chat_type === CHAT_TYPE.INDIVIDUAL) {
        message_type.value = MESSAGE_TYPE.INDIVIDUAL
      } else {
        if (me.category_id === USER_CATEGORY.FAMILY) {
          message_type.value = MESSAGE_TYPE.FAMILY
        } else {
          message_type.value = MESSAGE_TYPE.CARE_MEMBER
        }
      }
    }

    const makeReportMode = computed(() => root.$store.getters.makeReportMode)
    const setMakeReportMode = payload => {
      root.$store.dispatch(SET_MAKE_REPORT_MODE, payload)
      if (!payload) {
        root.$store.dispatch(CLEAR_REPORT_MESSAGES)
      }
    }

    const showMakeReportModal = ref(false)
    const closeMakeReportModal = () => {
      showMakeReportModal.value = false
    }
    const openMakeReportModal = () => {
      showMakeReportModal.value = true
    }

    const previewUrl = ref('')
    const previewFile = ref('')
    const showPreviewDialog = ref(false)
    const preview = message => {
      previewFile.value = message
      getS3Url(message.path).then(url => {
        previewUrl.value = url
        showPreviewDialog.value = true
      })
    }

    const download = () => {
      getS3Url(previewFile.value.path).then(url =>
        downloadFile(url, previewFile.value.file_name)
      )
    }

    const fetchFiles = () => {
      const filesPage = ref(1)
      root.$store.dispatch(FETCH_FILES, {
        chat_id: selectedChat.value.chat_id,
        message_type: message_type.value,
        page: filesPage.value
      })
    }
    const showFileListModal = ref(false)

    const setFamilyChat = () => root.$store.dispatch(SET_FAMILY_CHAT)
    const unSetFamilyChat = () => root.$store.dispatch(UNSET_FAMILY_CHAT)

    const recordModalStore = reactive({
      showEmergencyRecordModal: ref(false),
      closeEmergencyRecordModal: () => {
        recordModalStore.showEmergencyRecordModal = false
      },
      showHospitalRecordModal: ref(false),
      closeHospitalRecordModal: () => {
        recordModalStore.showHospitalRecordModal = false
      }
    })

    const searchChatInput = ref('')
    const blurInput = () => {
      searchChatInput.value.blur()
    }

    const isChatOwner = members =>
      members.find(m => m.user_id === me.user_id).is_owner

    return {
      isSp,
      isTablet,
      showEditChatModal,
      showDeleteChatConfirm,
      showSleepChatConfirm,
      showUnSleepChatConfirm,
      me,
      chats,
      selectedChat,
      loading,
      showMenu,
      editChat,
      showProfileModal,
      showProfileUser,
      showFamilyChat,
      focusedChat,
      showChatSelect,
      selectedChatType,
      selectedChatTypeName,
      chatSearchWord,
      CHAT_TYPE,
      USER_CATEGORY,
      FOCUSED_CHAT,
      USER_STATUS,
      message_type,
      showDeleteMessageConfirm,
      useFamilyChat,
      clear,
      deleteChat,
      sleepChat,
      toggleShowMenu,
      selectChat,
      openEditChatModal,
      closeEditChatModal,
      openDeleteChatConfirm,
      openSleepChatConfirm,
      openUnSleepChatConfirm,
      openProfileModal,
      inputChatSearchWord,
      clearChatSearchWord,
      fetchNextMessages,
      selectChatType,
      changeFocusedChat,
      openDeleteMessageConfirm,
      deleteMessage,
      makeReportMode,
      setMakeReportMode,
      showMakeReportModal,
      closeMakeReportModal,
      openMakeReportModal,
      showPreviewDialog,
      previewUrl,
      previewFile,
      preview,
      download,
      showCommonFiles,
      showFileListModal,
      backToChats,
      setFamilyChat,
      unSetFamilyChat,
      recordModalStore,
      RECORD_TYPE,
      MESSAGE_TYPE,
      CHAT_STATUS,
      searchChatInput,
      blurInput,
      isChatOwner,
      showMenuIsTop,
      windowHeight: getWindowHeight(195)
    }
  }
})
</script>
