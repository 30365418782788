import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login/Login.vue'
import Chat from '@/views/Chat/Chat.vue'
import Contact from '@/views/Contacts/Contacts.vue'
import Other from '@/views/Other/Other.vue'
import Schedule from '@/views/Schedule/Schedule'
import Profile from '@/views/Profile/Profile'
import Files from '@/views/Files/Files'
import Identification from '@/views/Identification/Identification'
import IdentificationSelect from '@/views/IdentificationSelect/IdentificationSelect'
import ProfileRegister from '@/views/ProfileRegister/ProfileRegister'
import UserRegistered from '@/views/UserRegistered'
import UserConfirmation from '@/views/UserConfirmation'
import ReissuePassword from '@/views/ReissuePassword/ReissuePassword'
import ResetPassword from '@/views/ResetPassword/ResetPassword'
import BusinessProfileRegister from '@/views/BusinessProfileRegister/BusinessProfileRegister'
import Manage from '@/views/Manage/Manage'
import ManageStaff from '@/views/ManageStaff/ManageStaff'
import ManagePrice from '@/views/ManagePrice/ManagePrice'
import Sample from '@/views/Sample'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'chats' }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { showSideBar: false, needLogin: true }
  },
  {
    path: '/user_registered',
    name: 'user_registered',
    component: UserRegistered,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/chat/:chat_type?',
    name: 'chats',
    component: Chat,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/chat/:chat_type/:chat_id',
    name: 'chat',
    component: Chat,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/schedule/:schedule_id?',
    name: 'schedule',
    component: Schedule,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/files',
    name: 'files',
    component: Files,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/identification',
    name: 'identification',
    component: Identification,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/identification/select',
    name: 'identification_select',
    component: IdentificationSelect,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/other/:layer1?/:layer2?',
    name: 'other',
    component: Other,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/profile_register/:step',
    name: 'profile_register',
    component: ProfileRegister,
    meta: { showSideBar: false, needLogin: true }
  },
  {
    path: '/user_confirmation/:confirm_id',
    name: 'user_confirmation',
    component: UserConfirmation,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/reissue_password',
    name: 'reissue_password',
    component: ReissuePassword,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/reset_password/:token/:email',
    name: 'reset_password',
    component: ResetPassword,
    meta: { showSideBar: false, needLogin: false }
  },
  {
    path: '/business_profile_register',
    name: 'business_profile_register',
    component: BusinessProfileRegister,
    meta: { showSideBar: false, needLogin: true }
  },
  {
    path: '/manage',
    name: 'manage',
    component: Manage,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/manage/staff',
    name: 'manage_staff',
    component: ManageStaff,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/manage/price',
    name: 'manage_price',
    component: ManagePrice,
    meta: { showSideBar: true, needLogin: true }
  },
  {
    path: '/sample',
    name: 'sample',
    component: Sample,
    meta: { showSideBar: false, needLogin: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
