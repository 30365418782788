export const SET_LOADING = 'SET_LOADING'
export const SET_MAINTENANCE_MESSAGE = 'SET_MAINTENANCE_MESSAGE'
export const POST_LOGIN = 'POST_LOGIN'
export const POST_LOGOUT = 'POST_LOGOUT'
export const FETCH_ME = 'FETCH_ME'
export const POST_MESSAGE = 'POST_MESSAGE'
export const PUT_MESSAGE = 'PUT_MESSAGE'
export const POST_MESSAGE_READ = 'POST_MESSAGE_READ'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const FETCH_MESSAGES = 'FETCH_MESSAGES'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const EDIT_MESSAGE = 'EDIT_MESSAGE'
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE'
export const POST_USER_PROFILE = 'POST_USER_PROFILE'
export const POST_USER_REGISTER = 'POST_USER_REGISTER'
export const PATCH_USER_REGISTER_CONFIRM = 'PATCH_USER_REGISTER_CONFIRM'
export const GET_USER_SEARCH = 'GET_USER_SEARCH'
export const CLEAR_USER_SEARCH_RESULT = 'CLEAR_USER_SEARCH_RESULT'
export const POST_CONTACT = 'POST_CONTACT'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const FETCH_CONTACTS = 'FETCH_CONTACTS'
export const INPUT_CONTACT_SEARCH_WORD = 'INPUT_CONTACT_SEARCH_WORD'
export const CLEAR_CONTACT_SEARCH_WORD = 'CLEAR_CONTACT_SEARCH_WORD'
export const POST_CHAT = 'POST_CHAT'
export const PUT_CHAT = 'PUT_CHAT'
export const PATCH_CHAT = 'PATCH_CHAT'
export const DELETE_CHAT = 'DELETE_CHAT'
export const PUT_CHAT_MEMBERS = 'PUT_CHAT_MEMBERS'
export const PATCH_CHAT_MEMBER = 'PATCH_CHAT_MEMBER'
export const PUT_CHAT_OWNER = 'PUT_CHAT_OWNER'
export const FETCH_CHATS = 'FETCH_CHATS'
export const INPUT_CHAT_SEARCH_WORD = 'INPUT_CHAT_SEARCH_WORD'
export const CLEAR_CHAT_SEARCH_WORD = 'CLEAR_CHAT_SEARCH_WORD'
export const TOGGLE_FAMILY_CHAT = 'TOGGLE_FAMILY_CHAT'
export const UNSET_FAMILY_CHAT = 'UNSET_FAMILY_CHAT'
export const SET_FAMILY_CHAT = 'SET_FAMILY_CHAT'
export const SELECT_CHAT_TYPE = 'SELECT_CHAT_TYPE'
export const SET_LAST_READ_MESSAGE_ID = 'SET_LAST_READ_MESSAGE_ID'
export const SET_MAKE_REPORT_MODE = 'SET_MAKE_REPORT_MODE'
export const SET_REPORT_MESSAGES = 'SET_REPORT_MESSAGES'
export const CLEAR_REPORT_MESSAGES = 'CLEAR_REPORT_MESSAGES'
export const FETCH_RECORDS = 'FETCH_RECORDS'
export const CLEAR_RECORDS = 'CLEAR_RECORDS'
export const POST_RECORD = 'POST_RECORD'
export const FETCH_FILES = 'FETCH_FILES'
export const DELETE_FILE = 'DELETE_FILE'
export const SET_IS_SP = 'SET_IS_SP'
export const SET_IS_TABLET = 'SET_IS_TABLET'
export const POST_FORGOT_PASSWORD = 'POST_FORGOT_PASSWORD'
export const PATCH_FORGOT_PASSWORD = 'PATCH_FORGOT_PASSWORD'
export const INPUT_FILE_SEARCH_WORD = 'INPUT_FILE_SEARCH_WORD'
export const CLEAR_FILE_SEARCH_WORD = 'CLEAR_FILE_SEARCH_WORD'
export const GET_POST_CODE = 'GET_POST_CODE'
export const FETCH_SCHEDULES = 'FETCH_SCHEDULES'
export const FETCH_NEXT_SCHEDULES = 'FETCH_NEXT_SCHEDULES'
export const POST_SCHEDULE = 'POST_SCHEDULE'
export const PUT_SCHEDULE = 'PUT_SCHEDULE'
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE'
export const SET_SELECTED_CHAT = 'SET_SELECTED_CHAT'
export const CLEAR_SCHEDULE = 'CLEAR_SCHEDULE'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const FETCH_BUSINESS_PROFILE = 'FETCH_BUSINESS_PROFILE'
export const POST_BUSINESS_PROFILE = 'POST_BUSINESS_PROFILE'
export const GET_USER_IDENTIFICATION = 'GET_USER_IDENTIFICATION'
export const POST_USER_IDENTIFICATION = 'POST_USER_IDENTIFICATION'
export const POST_STAFF = 'POST_STAFF'
export const GET_STAFFS = 'GET_STAFFS'
export const DELETE_STAFF = 'DELETE_STAFF'
export const PATCH_STAFF = 'PATCH_STAFF'
export const GET_INVITED_STAFFS = 'GET_INVITED_STAFFS'
export const GET_STAFF_CHATS = 'GET_STAFF_CHATS'
export const GET_STAFF_HISTORIES = 'GET_STAFF_HISTORIES'
export const GET_STAFF_MONTHLY_USAGE = 'GET_STAFF_MONTHLY_USAGE'
export const GET_STAFF_MONTHLY_USAGE_DETAIL = 'GET_STAFF_MONTHLY_USAGE_DETAIL'
export const INPUT_STAFF_SEARCH_WORD = 'INPUT_STAFF_SEARCH_WORD'
export const CLEAR_STAFF_SEARCH_WORD = 'CLEAR_STAFF_SEARCH_WORD'

export const POST_SAMPLE = 'POST_SAMPLE'
