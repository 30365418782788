<template src="./BusinessProfileRegister.html" />
<style lang="scss" scoped src="./BusinessProfileRegister.scss"></style>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import useMe from '@/comporsables/useMe'
import BusinessProfile from '@/compornents/BusinessProfile/BusinessProfile'
import { FETCH_BUSINESS_PROFILE } from '@/store/types'

export default defineComponent({
  name: 'BusinessProfileRegister',
  title() {
    return '決済代行情報登録'
  },
  components: {
    BusinessProfile
  },
  setup(_, { root }) {
    const { me } = useMe(root)
    if (!me.is_login) {
      root.$router.push({ name: 'login' })
    }

    const isManage = computed(() => root.$store.getters.isManage)
    // 事業管理者以外の場合はホームへ
    if (!isManage.value) {
      root.$router.push({ name: 'home' })
    }

    root.$store.dispatch(FETCH_BUSINESS_PROFILE).then(() => {
      if (root.$store.getters.business_profile) {
        root.$router.push({ name: 'home' })
      }
    })

    return {}
  }
})
</script>
